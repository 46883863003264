<template>
	<table class="tabs">
		<tr>
			<td
				class="tab"
				v-for="(tab, index) in tabs"
				v-bind:class="{ selected: selectedTab == index }"
				v-bind:key="tab[0]"
				:style="`width:${100 / tabs.length}%`"
				@click="goto(index)"
			>
				<img
					align="center"
					class="icon"
					:src="`/img/${selectedTab == index ? tab[2] : tab[1]}`"
				/>
				<div class="nav-text">{{ tab[0] }}</div>
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: "Tabs",
	props: ["id"],
	data() {
		return {
			selectedTab: 0,
			tabs: []
		};
	},
	created() {
		this.loadData();
		this.$eventHub.$on("keys-updated", this.loadData);
		this.$eventHub.$on("select-tab", this.selectTab);
	},
	beforeDestroy() {
		this.$eventHub.$off("keys-updated");
		this.$eventHub.$off("select-tab");
	},
	methods: {
		loadData: async function () {
			this.tabs = [];
			this.tabs.push(["Next", "flash.png", "flash-on.png", "/choice/next"]);
			if (this.$services.hasKey("Clean Slate") || this.$services.hasKey("Hat Trick")) {
				this.tabs.push(["Choices", "choices.png", "choices-on.png", "/choice-list"]);
			}
			if (this.$services.hasKey("The One")) {
				this.tabs.push(["News", "newspaper.png", "newspaper-on.png", "/news"]);
			}
			if (this.$services.hasKey("Score")) {
				this.tabs.push(["Score", "disc.png", "disc-on.png", "/score"]);
			}
			if (this.$services.hasKey("Tufte")) {
				this.tabs.push(["Trends", "trending-up.png", "trending-up-on.png", "/trends"]);
			}
			this.tabs.push(["Settings", "settings.png", "settings-on.png", "/settings"]);
			this.selectedTab = this.tabs.findIndex(t => t[3] == this.$router.currentRoute.path);
			this.selectedTab = this.selectedTab < 0 ? 0 : this.selectedTab;
		},
		goto: async function (idx) {
			this.selectedTab = idx;
			// console.log("route to:", this.tabs[idx][3])
			this.$router.push(this.tabs[idx][3])
		},
		selectTab: async function (name) {
			console.log("Select tab", name);
			this.selectedTab = this.tabs.findIndex(t => t[0] == name)
		}
	}
}

</script>

<style scoped>
.tabs {
	z-index: 10;
	position: fixed;
	left: 0px;
	right: 0px;
	margin-bottom: 0px;
	background: #cccccc;
	color: black;
	padding: 0px;
	bottom: 0;
	width: 100vw;
	height: 60px;
}
.tab {
	text-align: center;
}
.icon {
	max-height: 45px;
	max-width: 45px;
	height: 12vw;
	width: 12vw;
}
.nav-text {
	display: block;
	font-size: 12px;
}
.selected {
	color: #0054a6;
	font-weight: 600;
	background-color: #ccccdd;
}
</style>
